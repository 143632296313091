<template>
  <div
    class="card"
    :class="classes"
  >
    <div class="card__price">
      <basic-product-price
        v-if="localProduct?.prices?.length > 0"
        :product="localProduct"
        :prefix="pricePrefix"
        :suffix="$t('product.currency')"
        price-class="lead-1"
        suffix-class="body-1"
      />
    </div>

    <div
      class="card__name"
      v-html="$t(`content.products.${productId}.name`)"
    />

    <div class="card__description body-2">
      <div
        class="description__text"
        v-html="$t(`content.products.${productId}.description`)"
      />

      <template v-if="$te(`content.products.${productId}.altDescription`)">
        <div class="description__bullets">
          <div
            v-for="(text, index) in $t(`content.products.${productId}.altDescription`)"
            :key="index"
            class="bullet"
            v-html="text"
          />
        </div>
      </template>

      <template v-else>
        <div class="description__bullets">
          <ul class="product__bullets">
            <li
              v-for="(bullet, key) in $t(`content.products.${productId}.bulletPoints`)"
              :key="key"
              class="product__bullet"
            >
              <span v-text="bullet" />
              <the-icon
                v-if="$te(`content.products.${productId}.bulletPointsTooltip.${key}`)"
                v-tooltip="$t(`content.products.${productId}.bulletPointsTooltip.${key}`)"
                art="regular"
                name="circle-info"
                html-tooltip
              />
            </li>
          </ul>
        </div>
      </template>

      <div class="description__pricing">
        <basic-product-pricing
          v-if="localProduct?.prices?.length > 0"
          :product="localProduct"
        />
      </div>
    </div>

    <div class="card__criteria">
      <product-options
        v-if="categoryId && groupId && person"
        :category-id="categoryId"
        :group-id="groupId"
        :person="person"
        :product="localProduct"
        :source="$props.source"
        @change="selectOption"
      />
    </div>

    <div class="card__message">
      <template
        v-for="(message, index) in messages"
        :key="`message-${index}`"
      >
        <basic-message
          :actions="message.actions"
          :closable="message.closeable"
          :content="message.content"
          :severity="message.severity"
        />
      </template>
    </div>

    <div class="card__action">
      <product-action
        v-if="$props.personId"
        :person-id="$props.personId"
        :product="localProduct"
        :is-dirty="isDirty"
        :is-editable="isProductEditable"
        @add="add"
        @apply="apply"
        @remove="remove"
        @search="searchMedicalOffice"
      />

      <basic-button
        v-else-if="!$props.personId"
        :label="$t('benefits.widget.productLinkText')"
        @click="$emit('open-form')"
      />
    </div>

    <template v-if="!isReady">
      <div class="card__loader">
        <the-icon
          class="fa-spin"
          art="duo"
          name="loader"
        />
      </div>
    </template>
  </div>
</template>

<script setup>
import { computed, ref, watch, watchEffect } from 'vue'

import { events$, loading$ } from '@/services'

import basketStore from '@/store/basket'
import useI18n from '@/hooks/useI18n'
import usePersonSpecificProduct from '@/hooks/usePersonSpecificProduct'
import useProduct from '@/hooks/useProduct'
import useProductPersons from '@/hooks/useProductPersons'
import usePerson from '@/hooks/usePerson'
import useProductState from '@/hooks/useProductState'
import useSnackbar from '@/hooks/useSnackbar'
import { ReactivityUtil } from '@/utils/Reactivity'

import { BasicButton, BasicMessage, BasicProductPrice, BasicProductPricing } from '@/components/Basic'

import ProductAction from '@/components/Product/ProductAction'
import ProductOptions from '@/components/Product/Options'

import { NOTIFICATION, PRODUCT_STATE, PRODUCTS_THAT_REQUIRE_SIMULATION } from '@/config/constants'
import { EVENT_MEDICAL_OFFICE_MODAL_OPEN, EVENT_PRODUCT } from '@/config/events'
import { isProductReadOnlyHaX } from '@/utils/HaX'

// HOOKS
const { t } = useI18n()
const { getPerson } = usePerson()
const {
  changeProductOption,
  getProductMessages,
  getSimulatedProductSelection,
  removeProductFromPerson,
  updateProductForPerson,
} = usePersonSpecificProduct()
const { getCategoryIdFromProduct, getGroupIdFromProduct, isUpgrade } = useProduct()
const { addProductToOvpBasket, addProductWithDoctor } = useProductPersons()
const { state } = useProductState()
const { addToast } = useSnackbar()

// INIT
defineEmits(['open-form'])
const props = defineProps({
  isNarrow: {
    type: Boolean,
    default: false,
  },

  personId: {
    type: String,
    default: null,
  },

  product: {
    type: Object,
    required: true,
  },

  source: {
    type: String,
    required: true,
  },
})

// DATA
const localProduct = ref(props.product)
const isDirty = ref(false)
const isReady = ref(true)

// COMPUTED
const categoryId = computed(() => {
  return getCategoryIdFromProduct(props.product.productId)
})

const classes = computed(() => {
  return {
    'card--wide': !props.isNarrow,
    'product--invalid': !props.product.valid && !!localProduct.value?.messages?.find(m => m.type === 'ERROR'),
    'product--unavailable': !localProduct.value.valid && !!localProduct.value?.messages?.find(m => m.type === 'INFO'),
  }
})

const groupId = computed(() => {
  return getGroupIdFromProduct(props.product.productId)
})

const hasCalculationWarnings = computed(() => {
  return localProduct.value.calculationWarnings?.length > 0
})

const isProductEditable = computed(() => {
  return productState.value === PRODUCT_STATE.EDITABLE && !isProductReadOnlyHaX(productId.value)
})

const messages = computed(() => {
  if (props.personId) {
    return getProductMessages({
      categoryId: props.categoryId,
      hasCalculationWarnings: hasCalculationWarnings.value,
      modalRef: false,
      personId: props.personId,
      product: localProduct.value,
      productState: productState.value,
    })
  } else {
    return []
  }
})

const person = computed(() => {
  return getPerson(props.personId)
})

const pricePrefix = computed(() => {
  return localProduct.value?.showDiscountGroup && !localProduct.value.selected ? t('product.asof') : ''
})

const productId = computed(() => {
  return props.product.productId
})

const productState = computed(() => {
  if (!person.value) return

  const partnerNumber = person.value.partnerNumber
  return state(partnerNumber, productId.value)
})

// METHODS
async function add(__options) {
  if (__options.replace && !__options.forceAdd) {
    events$.emit(EVENT_PRODUCT.REPLACE_OPEN, {
      fromProductId: __options.productId,
      person: getPerson(props.personId),
      toProduct: props.product,
      source: props.source,
    })
  } else {
    try {
      loading$.start()
      const mode = isUpgrade(productId.value) ? 'ADD' : 'REPLACE'

      let basket = updateProductForPerson({ personId: props.personId, product: localProduct.value })
      basket = await addProductToOvpBasket(props.personId, productId.value, { basket, mode })
      basket = await basketStore.updateOvpBasket(basket)

      events$.emit(EVENT_PRODUCT.ADDED, {
        basket,
        categoryId: categoryId.value,
        personId: props.personId,
        productId: props.product.productId,
        source: props.source,
      })

      localProduct.value = props.product

      isDirty.value = false
      loading$.end()
    } catch (error) {
      loading$.failed()

      error.messages.forEach(message => {
        addToast({
          type: NOTIFICATION.ERROR,
          text: message,
        })
      })
    }
  }
}

async function apply() {
  loading$.start()

  try {
    const basket = updateProductForPerson({ personId: props.personId, product: localProduct.value })

    await basketStore.updateOvpBasket(basket)

    isDirty.value = false

    loading$.end()
  } catch (error) {
    console.error(error)
    loading$.failed()
  }
}

async function remove() {
  loading$.start()

  try {
    const __basket = await removeProductFromPerson(props.personId, productId.value)

    events$.emit(EVENT_PRODUCT.REMOVED, {
      basket: __basket,
      categoryId: categoryId.value,
      personId: props.personId,
      productId: productId.value,
      source: props.source,
    })

    localProduct.value = props.product

    isDirty.value = false
    loading$.end()
  } catch (error) {
    loading$.failed()

    error.messages.forEach(message => {
      addToast({
        type: NOTIFICATION.ERROR,
        text: message,
      })
    })
  }
}

function searchMedicalOffice() {
  events$.emit(EVENT_MEDICAL_OFFICE_MODAL_OPEN, {
    source: props.source,
    personId: props.personId,
    product: localProduct.value,
    callback: async data => {
      loading$.start()

      try {
        await addProductWithDoctor({
          categoryId: props.categoryId,
          doctor: data,
          personId: props.personId,
          productId: props.product.productId,
          source: props.source,
        })

        loading$.end()
      } catch {
        loading$.failed()
      }
    },
  })
}

async function selectOption(option) {
  loading$.start()

  try {
    const updatedProductCriteria = await changeProductOption({
      option,
      personId: props.personId,
      product: ReactivityUtil.clone(localProduct.value),
    })

    ReactivityUtil.reAssign(localProduct.value, updatedProductCriteria)
    if (localProduct.value.selected) {
      isDirty.value = true
    }
    loading$.end()
  } catch (error) {
    console.error(error)
    loading$.failed()
  }
}

// WATCHERS
watch(
  () => props.product,
  value => {
    localProduct.value = value
  },
  { deep: true }
)

watchEffect(async () => {
  // has person
  if (
    props.personId &&
    !props.product.selected &&
    PRODUCTS_THAT_REQUIRE_SIMULATION.includes(props.product.productId) &&
    basketStore.basket.existingCustomer
  ) {
    isReady.value = false

    try {
      const _product = await getSimulatedProductSelection(productId.value, props.personId)
      ReactivityUtil.reAssign(localProduct.value, _product)

      isReady.value = true
    } catch {
      // don't do a thing
    }
    isReady.value = true
  }
})
</script>

<style scoped>
.card {
  position: relative;
  display: grid;
  grid-row: 1 / -1;
  grid-template-columns: 1fr;
  grid-template-rows: subgrid;

  padding: var(--fixed-spacing-fix-06);

  border: 1px solid var(--outline-variant);
  border-radius: var(--radius-border-radius-03);

  &--unavailable {
    color: var(--on-surface-low);
  }

  .card__name {
    font-size: var(--font-size-bold-3xl);
    letter-spacing: var(--letter-spacing-bold-3xl);
    line-height: var(--line-height-bold-3xl);
    font-weight: var(--font-weight-bold);
    margin: 0 0 var(--fixed-spacing-fix-04);
  }

  .card__description {
    margin: 0 0 var(--fixed-spacing-fix-05);

    .description__text {
      margin: 0 0 var(--fixed-spacing-fix-02);
    }

    .description__bullets {
      margin: 0 0 0 var(--fixed-spacing-fix-04);
    }

    .description__pricing:deep(.pricing):not(:empty) {
      margin: var(--fixed-spacing-fix-02) 0 0;
    }
  }

  .card__message:not(:empty) {
    margin: 0 0 var(--fixed-spacing-fix-04);
  }

  .card__message .message + .message {
    margin: var(--fixed-spacing-fix-04) 0 0;
  }

  .card__criteria:not(:empty) {
    margin: 0 0 var(--fixed-spacing-fix-05);
  }

  .card__action:deep(.button) {
    width: 100%;
  }

  .product__bullet .icon {
    margin: 0 0 0 4px;
  }

  .card__loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: grid;
    text-align: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.64);

    .icon {
      align-self: center;
      justify-self: center;
      font-size: 24px;
    }
  }
}
</style>
