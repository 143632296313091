<template>
  <div
    class="ovp-a11y-container"
    :app-language="selectedLanguage"
    :app-name="appName$"
    :app-version="version$"
  >
    <screen-auth />
    <the-confirmation />
    <the-loading />
    <div id="modals"></div>
    <medical-office-search />
    <product-replace-modal />
    <product-suggestion-modal />
    <the-snackbar />
    <save-nudge v-if="isNudgingEnabled" />
  </div>
</template>

<script setup>
import { computed, inject, onBeforeMount, onMounted, watch } from 'vue'

import { events$, loading$ } from '@/services'

import masterDataAPI from '@/api/masterData'
import basketStore from '@/store/basket'
import partnerStore from '@/store/partner'
import useApplication from '@/hooks/useApplication'
import useBasket from '@/hooks/useBasket'
import useI18n from '@/hooks/useI18n'
import usePerson from '@/hooks/usePerson'
import useSession from '@/hooks/useSession'
import useSurvey from '@/hooks/useSurvey'
import useSync from '@/hooks/useSync'
import useUrl from '@/hooks/useUrl'

import ProductReplaceModal from '@/components/Product/Replace/ReplaceModal'
import ProductSuggestionModal from '@/components/Product/Replace/SuggestionModal'
import MedicalOfficeSearch from '@/components/MedicalSearch/MedicalOfficeSearch'
import ScreenAuth from '@/components/Screen/ScreenAuth'
import TheConfirmation from '@/components/Modal/Confirmation'
import TheLoading from '@/components/Container/Loading'
import TheSnackbar from '@/components/Basic/Snackbar'
import SaveNudge from '@/components/Basket/SaveNudge/SaveNudge'

import { registerBasketActionListeners, registerUpdateBasketListeners } from '@/helpers/GlobalEventHandler'

import { EVENT_DOTCOM_SURVEY_WEBSITE, EVENT_TRACKING, EVENT_URL } from '@/config/events'
import { CHANNEL, DEFAULT_DISTRIBUTION_PARTNER_NUMBER, SURVEY_TOUCHPOINT_WEBSITE } from '@/config/constants'

import '@/assets/css/import.css'

// HOOKS
const { getDvpUrl, initializeClearSequence, isReadyWithPartner } = useApplication()
const { updateLanguage } = useBasket()
const { browserLanguage, selectedLanguage } = useI18n()
const { createPerson } = usePerson()
const { autostart } = useSession()
const { triggerSurvey } = useSurvey()
const { registerSync } = useSync()
const { getParamFromUrl, react } = useUrl()
const { sanitizePrefillParams } = useUrl()

// INIT
const appName$ = inject('appName$')
const version$ = inject('version$')

// COMPUTED
const isNudgingEnabled = computed(() => {
  return basketStore.basket.persons?.length > 0 && !basketStore.basket.existingCustomer
})

// METHODS
function emitEventWhenReady() {
  return new Promise(function (resolve) {
    if (isReadyWithPartner.value) resolve()

    const unwatch = watch(
      () => isReadyWithPartner.value,
      () => {
        unwatch()
        resolve()
      }
    )
  })
}

async function prefillUser() {
  const contractStartDate = basketStore.basket.contractStartDate

  if (getParamFromUrl('open') === 'prefill' && getParamFromUrl('data')) {
    try {
      loading$.start()

      const prefillData = sanitizePrefillParams(getParamFromUrl('data'))

      const locations = await masterDataAPI.getLocations({
        query: prefillData.zipCode,
        contractStartDate,
      })
      const location = locations.find(l => {
        return (
          l.primeRegion === prefillData.primeRegion &&
          l.locationNumber === prefillData.locationNumber &&
          l.zipCode === prefillData.zipCode &&
          l.zipCodeAddon === prefillData.zipCodeAddon
        )
      })

      if (!location) throw new Error()

      const formData = {
        contractStartDate,
        address: location,
        persons: [
          await createPerson({
            dateOfBirth: prefillData.dateOfBirth,
            firstName: prefillData.firstName,
            gender: prefillData.gender,
          }),
        ],
      }

      await basketStore.updateOvpBasket(formData)
    } catch {
      // noop
    }

    loading$.end()
  }
}

// LIFECYCLE HOOKS
onBeforeMount(async () => {
  initializeClearSequence()
  const __basket = await autostart()

  if (basketStore.isTemporaryBasket()) {
    await partnerStore.install({
      contractStartDate: __basket.contractStartDate,
      language: __basket.language,
      collectiveNumber: null,
      partnerNumber: null,
      distributionPartnerNumber: DEFAULT_DISTRIBUTION_PARTNER_NUMBER,
    })
  }

  if (basketStore.basket.language !== browserLanguage) {
    await updateLanguage({ language: browserLanguage })
  }

  prefillUser()

  events$.once(EVENT_DOTCOM_SURVEY_WEBSITE, () => {
    triggerSurvey(SURVEY_TOUCHPOINT_WEBSITE)
  })
})

onMounted(() => {
  registerUpdateBasketListeners()
  registerBasketActionListeners()

  emitEventWhenReady().then(() => {
    // tab-sync needs to be registered, after all the BE-/localStorage operations are done
    registerSync()

    // react to URL-params (e.g. open login)
    react()
  })

  events$.on(EVENT_URL.GOTO, opts => {
    // @note: entered should only be triggered, if entry happens from fresh productWidget
    if (basketStore.basket.channel === CHANNEL.PRODUCTWIDGET) {
      events$.emit(EVENT_TRACKING.ENTERED, { source: CHANNEL.PRODUCTWIDGET })
    }

    window.location.href = `${getDvpUrl(selectedLanguage.value)}/#/${opts.route.path}`
  })

  document.addEventListener('keydown', event => {
    // tab, left, up, right, down
    if ([9, 37, 38, 39, 40].includes(event.keyCode)) {
      window.setTimeout(() => {
        if (document.activeElement?.classList && document.activeElement.classList.contains('outline')) {
          document.activeElement.classList.add('has-outline')
        }
      })
    }
  })
})
</script>

<style name="global">
:root {
  /* the values have to be 5 < x < 99 accoring to dotcom */
  --dvp-stack-level-element: 11;
  --dvp-stack-level-popup-menu: 12;
  --dvp-stack-level-modal: 13;
  --dvp-stack-level-modal-stacked: 14; /* exception for medical-office-search modal as it can be opened within another modal */
  --dvp-stack-level-popup-notification: 15;
  --dvp-stack-level-tooltip: 1000; /* needs to be higher than dotcom-navigation (z-index: 999) */
  --dvp-stack-level-system-notification: 17;
  --dvp-stack-level-screen-blocker: 18;
}

#basketWidget *:focus,
.productWidget *:focus,
#fab-widget *:focus,
.sanitasOvpEntry *:focus,
.dvp-modal-container *:focus,
.dropdown[role='listbox'] *:focus {
  outline: none;
}

.tippy-box[data-theme~='dvp'] {
  min-width: 144px;
  padding: var(--fixed-spacing-fix-06);
  background-color: var(--surface);
  border-radius: var(--fixed-border-radius-fix-02);
  box-shadow: var(--elevation-level-5);

  .tippy-content {
    font-weight: var(--font-weight-regular);
    font-size: var(--font-size-regular-sm);
    letter-spacing: var(--letter-spacing-regular-sm);
    color: var(--on-surface);
    padding: 0;
  }

  .tippy-arrow {
    color: var(--surface);

    &::before {
      transform: scale(1.25);
    }
  }
}

.tippy-box[data-theme~='card'] {
  background-color: var(--surface);
  border-radius: var(--fixed-border-radius-fix-02);
  box-shadow: var(--elevation-level-5);
  padding: var(--fixed-spacing-fix-02);
  margin: 0;
  & > .tippy-content {
    padding: 0;
    margin: 0;
    height: max-content;

    & > .card__image {
      margin: 0;
      padding: 0;
      width: 100%;
    }
  }

  .tippy-arrow {
    color: var(--surface);

    &::before {
      transform: scale(1.25);
    }
  }
}

.tippy-box[data-placement='top-end'] > {
  .tippy-arrow {
    right: 20px !important;
    left: initial !important;
  }
}
</style>
